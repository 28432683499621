import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import "../sass/index.scss"
import logo from "../images/logo2.png"
import queryString from "query-string"

//components
import VioletBell from "../components/Content/VioletBell"
import CarierPath from "../components/Content/CarierPath"
import Test from "../components/Test/Test"
import Form from "../components/Test/Form"
import FinishScreen from "../components/Test/FinishScreen"
import Footer from "../components/Footer/Footer"
import Students from "../components/Content/Students"

const TestPage = props => {
  const dispatch = useDispatch()
  const data = props.data
  const homePage = data.wordpressAcfPages.acf

  const { testVisible, finishScreen, carierPathVisible } = useSelector(
    state => ({
      ...state.testReducer,
    })
  )
  const parsedLocation = queryString.parse(props.location.search)

  useEffect(() => {
    dispatch({
      type: "SET_TEST",
      testTitle: homePage.testTitle,
      testDetails: homePage.test_desc,
      questions: homePage.questions,
    })
    parsedLocation.email &&
      dispatch({
        type: "SAVE_USER",
        email: parsedLocation.email,
      })
  }, [])

  return (
    <section style={{ marginTop: -30 }}>
      <VioletBell positionImage={"left"} otherClass={"center"}>
        <a href="https://coderslab.pl/" target="_blank">
          <img src={logo} alt={"Coders Lab logo"} />
        </a>
        {!parsedLocation.email && !testVisible ? (
          <Form data={homePage.violetBellForm_2} />
        ) : (
          <Test singlePage={true} />
        )}
      </VioletBell>
      <div id={"finishScreen"}>{finishScreen && <FinishScreen />}</div>
      {carierPathVisible && (
        <CarierPath title={homePage.CarierPathTitle} paths={homePage.paths} />
      )}
      <Students
        title={homePage.students_title}
        data={homePage.kursanci}
        link={homePage["students_link"]}
      />
      <Footer
        title={homePage["footer_title"]}
        contact={homePage["footer_person_contact"]}
        socials={homePage["footer_socials"]}
      />
    </section>
  )
}

export default TestPage

export const pageQuery = graphql`
  query {
    wordpressAcfPages {
      acf {
        title
        cards {
          comment
          author
          add_text
          cl_comment
        }
        violetBellTitle
        violetBellText
        diffProfTitle
        diffProfParts {
          title
          list {
            item
          }
          description
        }
        violetBellTitle_2
        violetBellText_2
        violetBellForm_2
        CarierPathTitle
        paths {
          pathTitle
        }
        testTitle
        test_desc
        questions {
          question
          answers {
            answer
          }
          question_id
        }

        paths {
          pathTitle
          points {
            placeName
            addInfo
          }
          wordpress_id
        }
        students_title
        kursanci {
          name
          info
          who_is
          image {
            source_url
          }
          link
        }
        students_link
        footer_title
        footer_person_contact
        footer_socials {
          link
        }
      }
    }
  }
`
